import React, { useState, useEffect, useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import Header from './Header';
import Footer from './Footer';
import { OrganizationContext } from '../context/OrganizationContext';

const ProductDetailsPage = ({ onSignOut }) => {
  const [product, setProduct] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedProduct, setEditedProduct] = useState(null);
  const { closetId, productId } = useParams();
  const [conditions, setConditions] = useState([]);
  const [sources, setSources] = useState([]);
  const [availabilities, setAvailabilities] = useState([]);
  const [platforms, setPlatforms] = useState([]);

  const organization = useContext(OrganizationContext);

  useEffect(() => {
    fetchProductDetails();
  }, [closetId, productId]);

  useEffect(() => {
    // Set these from your org context data
    setConditions(organization.conditions);
    setSources(organization.sources);
    setAvailabilities(organization.availabilities);
    setPlatforms(organization.platforms);
  }, []);

  const fetchProductDetails = async () => {
    try {
      const response = await axios.get(`https://api.thriftforge.com/closets/${closetId}/products/${productId}`);
      setProduct(response.data);
    } catch (error) {
      console.error('Error fetching product details:', error.message);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString || dateString.startsWith('0000')) return 'N/A';
    return new Date(dateString).toLocaleDateString();
  };

  const formatPrice = (price) => {
    if (price === null || price === undefined) return 'N/A';
    const dollars = price / 100000; // Convert millicents to dollars
    return `$${dollars.toFixed(2)}`;
  };

  const handleEdit = () => {
    setEditedProduct({ ...product });
    setIsEditing(true);
  };

  const handleSave = async () => {
    try {
      const response = await axios.put(
        `https://api.thriftforge.com/closets/${closetId}/products/${productId}`,
        editedProduct
      );
      setProduct(response.data);
      setIsEditing(false);
    } catch (error) {
      console.error('Error updating product:', error.message);
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    setEditedProduct(null);
  };

  const handleInputChange = (field, value) => {
    let processedValue = value;
    
    // Handle special cases
    if (field === 'cost' || field === 'soldPrice' || field === 'shippingFee' || field === 'sellingFee') {
      // Convert dollar input to millicents
      processedValue = Math.round(parseFloat(value || 0) * 100000);
    } else if (field === 'datePurchased' || field === 'dateListed') {
      // Ensure proper date format
      processedValue = value || new Date().toISOString();
    }

    setEditedProduct(prev => ({
      ...prev,
      [field]: processedValue
    }));
  };

  const handleDownloadImages = async () => {
    if (!product.images || product.images.length === 0) return;
    
    try {
      // Download each image
      for (const image of product.images) {
        const response = await fetch(`https://images.thriftforge.com/${image.location}`);
        const blob = await response.blob();
        
        // Create a download link
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = image.location.split('/').pop(); // Use the filename from the path
        
        // Trigger download
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }
    } catch (error) {
      console.error('Error downloading images:', error);
    }
  };

  if (!product) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <Header onSignOut={onSignOut} />
      <main className="flex-grow container mx-auto py-8 px-4 max-w-5xl">
        <Link 
          to={`/closet/${product.closetId}/products`} 
          className="inline-flex items-center text-sm text-gray-600 hover:text-gray-900 mb-6 transition-colors"
        >
          <span className="mr-2">←</span> Back to Products
        </Link>

        <div className="space-y-8">
          {/* Header Section - Modified to include image */}
          <div className="border-b border-gray-200 pb-4 flex justify-between items-start gap-6">
            {/* Add First Image */}
            {product.images && product.images.length > 0 && (
              <img 
                src={`https://images.thriftforge.com/${product.images[0].location}`}
                alt={product.title}
                className="w-48 h-48 object-cover rounded-lg shadow-sm flex-shrink-0" 
              />
            )}
            
            <div className="flex-grow">
              <h2 className="text-3xl font-light text-gray-900">
                {isEditing ? (
                  <input
                    type="text"
                    value={editedProduct.title}
                    onChange={(e) => handleInputChange('title', e.target.value)}
                    className="border rounded px-2 py-1"
                  />
                ) : (
                  product.title
                )}
              </h2>
              <p className="text-sm text-gray-500 mt-2">SKU: {product.sku || 'N/A'}</p>
            </div>

            <div className="space-x-2 flex-shrink-0">
              {isEditing ? (
                <div className="space-x-2">
                  <button
                    onClick={handleSave}
                    className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
                  >
                    Save
                  </button>
                  <button
                    onClick={handleCancel}
                    className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
                  >
                    Cancel
                  </button>
                </div>
              ) : (
                <>
                  <button
                    onClick={handleDownloadImages}
                    className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
                  >
                    Download Images
                  </button>
                  <button
                    onClick={() => {
                      axios.get(`https://api.thriftforge.com/closets/${closetId}/products/${productId}/analyze`)
                        .catch(error => console.error('Error analyzing product:', error.message));
                    }}
                    className="bg-purple-500 text-white px-4 py-2 rounded hover:bg-purple-600"
                  >
                    Analyze
                  </button>
                  <button
                    onClick={handleEdit}
                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                  >
                    Edit
                  </button>
                </>
              )}
            </div>
          </div>

          {/* Main Content */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
            <div className="space-y-6">
              <section>
                <h3 className="text-lg font-medium text-gray-900 mb-4">Product Details</h3>
                <div className="space-y-3 text-sm">
                  {/* Basic Details */}
                  <div className="flex justify-between py-2 border-b border-gray-100">
                    <span className="text-gray-600">SKU</span>
                    <span className="text-gray-900">
                      {isEditing ? (
                        <input
                          type="text"
                          value={editedProduct.sku}
                          onChange={(e) => handleInputChange('sku', e.target.value)}
                          className="border rounded px-2 py-1"
                        />
                      ) : (
                        product.sku || 'N/A'
                      )}
                    </span>
                  </div>

                  <div className="flex justify-between py-2 border-b border-gray-100">
                    <span className="text-gray-600">Brand</span>
                    <span className="text-gray-900">
                      {isEditing ? (
                        <input
                          type="text"
                          value={editedProduct.brand}
                          onChange={(e) => handleInputChange('brand', e.target.value)}
                          className="border rounded px-2 py-1"
                        />
                      ) : (
                        product.brand || 'N/A'
                      )}
                    </span>
                  </div>

                  <div className="flex justify-between py-2 border-b border-gray-100">
                    <span className="text-gray-600">Size</span>
                    <span className="text-gray-900">
                      {isEditing ? (
                        <input
                          type="text"
                          value={editedProduct.size}
                          onChange={(e) => handleInputChange('size', e.target.value)}
                          className="border rounded px-2 py-1"
                        />
                      ) : (
                        product.size || 'N/A'
                      )}
                    </span>
                  </div>

                  <div className="flex justify-between py-2 border-b border-gray-100">
                    <span className="text-gray-600">Color</span>
                    <span className="text-gray-900">
                      {isEditing ? (
                        <input
                          type="text"
                          value={editedProduct.color}
                          onChange={(e) => handleInputChange('color', e.target.value)}
                          className="border rounded px-2 py-1"
                        />
                      ) : (
                        product.color || 'N/A'
                      )}
                    </span>
                  </div>

                  <div className="flex justify-between py-2 border-b border-gray-100">
                    <span className="text-gray-600">Condition</span>
                    <span className="text-gray-900">
                      {isEditing ? (
                        <select
                          value={editedProduct.conditionId}
                          onChange={(e) => handleInputChange('conditionId', parseInt(e.target.value))}
                          className="border rounded px-2 py-1"
                        >
                          {conditions.map(condition => (
                            <option key={condition.id} value={condition.id}>
                              {condition.name}
                            </option>
                          ))}
                        </select>
                      ) : (
                        product.condition?.name || 'N/A'
                      )}
                    </span>
                  </div>

                  <div className="flex justify-between py-2 border-b border-gray-100">
                    <span className="text-gray-600">Source</span>
                    <span className="text-gray-900">
                      {isEditing ? (
                        <select
                          value={editedProduct.sourceId}
                          onChange={(e) => handleInputChange('sourceId', parseInt(e.target.value))}
                          className="border rounded px-2 py-1"
                        >
                          {sources.map(source => (
                            <option key={source.id} value={source.id}>
                              {source.name}
                            </option>
                          ))}
                        </select>
                      ) : (
                        product.source?.name || 'N/A'
                      )}
                    </span>
                  </div>

                  <div className="flex justify-between py-2 border-b border-gray-100">
                    <span className="text-gray-600">Availability</span>
                    <span className="text-gray-900">
                      {isEditing ? (
                        <select
                          value={editedProduct.availabilityId}
                          onChange={(e) => handleInputChange('availabilityId', parseInt(e.target.value))}
                          className="border rounded px-2 py-1"
                        >
                          {availabilities.map(availability => (
                            <option key={availability.id} value={availability.id}>
                              {availability.Name}
                            </option>
                          ))}
                        </select>
                      ) : (
                        product.availability?.Name || 'N/A'
                      )}
                    </span>
                  </div>

                  <div className="flex justify-between py-2 border-b border-gray-100">
                    <span className="text-gray-600">Draft</span>
                    <span className="text-gray-900">
                      {isEditing ? (
                        <input
                          type="checkbox"
                          checked={editedProduct.isDraft}
                          onChange={(e) => handleInputChange('isDraft', e.target.checked)}
                          className="h-4 w-4 text-blue-600 rounded border-gray-300 focus:ring-blue-500"
                        />
                      ) : (
                        product.isDraft ? 'Yes' : 'No'
                      )}
                    </span>
                  </div>
                </div>
              </section>
            </div>

            <div className="space-y-6">
              <section>
                <h3 className="text-lg font-medium text-gray-900 mb-4">Financial Details</h3>
                <div className="space-y-3 text-sm">
                  <div className="flex justify-between py-2 border-b border-gray-100">
                    <span className="text-gray-600">Cost</span>
                    <span className="text-gray-900">
                      {isEditing ? (
                        <input
                          type="number"
                          step="0.01"
                          value={(editedProduct.cost / 100000).toFixed(2)}
                          onChange={(e) => handleInputChange('cost', e.target.value)}
                          className="border rounded px-2 py-1"
                        />
                      ) : (
                        formatPrice(product.cost)
                      )}
                    </span>
                  </div>

                  <div className="flex justify-between py-2 border-b border-gray-100">
                    <span className="text-gray-600">Sold Price</span>
                    <span className="text-gray-900">
                      {isEditing ? (
                        <input
                          type="number"
                          step="0.01"
                          value={(editedProduct.soldPrice / 100000).toFixed(2)}
                          onChange={(e) => handleInputChange('soldPrice', e.target.value)}
                          className="border rounded px-2 py-1"
                        />
                      ) : (
                        formatPrice(product.soldPrice)
                      )}
                    </span>
                  </div>

                  <div className="flex justify-between py-2 border-b border-gray-100">
                    <span className="text-gray-600">Shipping Fee</span>
                    <span className="text-gray-900">
                      {isEditing ? (
                        <input
                          type="number"
                          step="0.01"
                          value={(editedProduct.shippingFee / 100000).toFixed(2)}
                          onChange={(e) => handleInputChange('shippingFee', e.target.value)}
                          className="border rounded px-2 py-1"
                        />
                      ) : (
                        formatPrice(product.shippingFee)
                      )}
                    </span>
                  </div>

                  <div className="flex justify-between py-2 border-b border-gray-100">
                    <span className="text-gray-600">Platform Sold On</span>
                    <span className="text-gray-900">
                      {isEditing ? (
                        <select
                          value={editedProduct.platformSoldOnId}
                          onChange={(e) => handleInputChange('platformSoldOnId', parseInt(e.target.value))}
                          className="border rounded px-2 py-1"
                        >
                          {platforms.map(platform => (
                            <option key={platform.id} value={platform.id}>
                              {platform.Name}
                            </option>
                          ))}
                        </select>
                      ) : (
                        product.platformSoldOn?.Name || 'N/A'
                      )}
                    </span>
                  </div>

                  <div className="flex justify-between py-2 border-b border-gray-100">
                    <span className="text-gray-600">Date Purchased</span>
                    <span className="text-gray-900">
                      {isEditing ? (
                        <input
                          type="date"
                          value={editedProduct.datePurchased.split('T')[0]}
                          onChange={(e) => handleInputChange('datePurchased', e.target.value)}
                          className="border rounded px-2 py-1"
                        />
                      ) : (
                        formatDate(product.datePurchased)
                      )}
                    </span>
                  </div>

                  <div className="flex justify-between py-2 border-b border-gray-100">
                    <span className="text-gray-600">Date Listed</span>
                    <span className="text-gray-900">
                      {isEditing ? (
                        <input
                          type="date"
                          value={editedProduct.dateListed.split('T')[0]}
                          onChange={(e) => handleInputChange('dateListed', e.target.value)}
                          className="border rounded px-2 py-1"
                        />
                      ) : (
                        formatDate(product.dateListed)
                      )}
                    </span>
                  </div>
                </div>
              </section>
            </div>
          </div>

          {/* Notes Section */}
          <section className="pt-6 border-t border-gray-200">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Notes</h3>
            {isEditing ? (
              <textarea
                value={editedProduct.notes}
                onChange={(e) => handleInputChange('notes', e.target.value)}
                className="w-full border rounded px-2 py-1"
                rows="4"
              />
            ) : (
              <p className="text-gray-600 text-sm">{product.notes || 'No notes available.'}</p>
            )}
          </section>

          {/* Images Section */}
          {product.images && product.images.length > 0 && (
            <section className="pt-6 border-t border-gray-200">
              <h3 className="text-lg font-medium text-gray-900 mb-4">Images</h3>
              <div className="grid grid-cols-2 md:grid-cols-3 gap-6">
                {product.images.map((image, index) => (
                  <img 
                    key={index} 
                    src={`https://images.thriftforge.com/${image.location}`}
                    alt={`Product ${index + 1}`} 
                    className="w-full h-64 object-cover rounded-lg shadow-sm" 
                  />
                ))}
              </div>
            </section>
          )}
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default ProductDetailsPage;
