import React, { useState, useEffect, useCallback, useContext } from 'react'
import { useParams, Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import Header from './Header'
import Footer from './Footer'
import debounce from 'lodash/debounce'
import { OrganizationContext } from '../context/OrganizationContext'
import DateTimePicker from 'react-datetime-picker';

import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';

const ProductPage = ({ onSignOut }) => {
  const organization = useContext(OrganizationContext);
  const [products, setProducts] = useState([])
  const [closet, setCloset] = useState(null)
  const [totalCount, setTotalCount] = useState(0)
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(20)
  const [filters, setFilters] = useState(() => {
    // Load filters from localStorage or use defaults
    const savedFilters = localStorage.getItem('productFilters');
    return savedFilters ? JSON.parse(savedFilters) : {
      q: '',
      is_draft: null,
      date_listed_min: null,
      date_listed_max: null,
      date_purchased_min: null,
      date_purchased_max: null,
      style_id: '',
      days_listed: '',
      price_min: '',
      price_max: '',
    };
  });
  const { closetId } = useParams()
  const navigate = useNavigate();
  const [deletingProductId, setDeletingProductId] = useState(null);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const fetchData = useCallback(async () => {
    try {
      const queryParams = new URLSearchParams({
        page,
        page_size: pageSize,
      });

      Object.entries(filters).forEach(([key, value]) => {
        if (value !== null && value !== '') {
          if (value instanceof Date) {
            queryParams.append(key, value.toISOString());
          } else if (key === 'price_min' || key === 'price_max') {
            queryParams.append(key, Math.round(parseFloat(value) * 100));
          } else {
            queryParams.append(key, value);
          }
        }
      });

      const [closetResponse, productsResponse] = await Promise.all([
        axios.get(`https://api.thriftforge.com/closets/${closetId}`),
        axios.get(`https://api.thriftforge.com/closets/${closetId}/products/search?${queryParams}`)
      ]);

      setCloset(closetResponse.data);
      setProducts(productsResponse.data.products);
      setTotalCount(productsResponse.data.total_count);
    } catch (error) {
      console.error('Error fetching data:', error.message);
    }
  }, [closetId, page, pageSize, filters]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleFilterChange = useCallback(
    debounce((key, value) => {
      const newFilters = { ...filters, [key]: value };
      setFilters(newFilters);
      localStorage.setItem('productFilters', JSON.stringify(newFilters));
      setPage(1); // Reset to first page when filter changes
    }, 300),
    [filters]
  );

  const renderSelectFilter = (key, options, placeholder) => (
    <select
      className="w-full p-1 text-sm border rounded"
      onChange={(e) => handleFilterChange(key, e.target.value)}
    >
      <option value="">{placeholder}</option>
      {options.map(option => (
        <option key={option.id} value={option.id}>
          {option.name || option.Name}
        </option>
      ))}
    </select>
  )

  const renderFilterInput = (key, placeholder) => (
    <input
      type="text"
      placeholder={placeholder}
      className="w-full p-1 text-sm border rounded"
      onChange={(e) => handleFilterChange(key, e.target.value)}
    />
  )

  const formatDate = (dateString) => {
    if (!dateString || dateString.startsWith('0000')) return 'N/A';
    return new Date(dateString).toLocaleDateString();
  }

  const formatPrice = (price) => {
    if (price === null || price === undefined) return 'N/A';
    const dollars = price / 100000; // Convert millicents to dollars
    return `$${dollars.toFixed(2)}`;
  }

  const formatCategories = (categories) => {
    return categories ? categories.map(c => c.name).join(', ') : 'N/A';
  }

  const handleRowClick = (productId) => {
    navigate(`/closet/${closetId}/products/${productId}`);
  };

  const getFirstImageUrl = (images) => {
    if (!images || images.length === 0) return null;
    return images[0].location;
  }

  const handleDeleteProduct = async (productId, event) => {
    event.stopPropagation(); // Prevent row click event
    try {
      await axios.delete(`https://api.thriftforge.com/closets/${closetId}/products/${productId}`);
      setDeletingProductId(null);
      fetchData(); // Refresh the product list
    } catch (error) {
      console.error('Error deleting product:', error.message);
    }
  };

  const handleDeleteClick = (productId, event) => {
    event.stopPropagation();
    setDeletingProductId(productId);
  };

  const handleRefresh = async () => {
    setIsRefreshing(true);
    await fetchData();
    setIsRefreshing(false);
  };

  const getCategoriesAtLevel = useCallback((level) => {
    if (level === 0) {
      return organization.categories;
    }
    
    let currentCategories = organization.categories;
    for (let i = 0; i < level; i++) {
      const selectedCategory = selectedCategories[i];
      if (!selectedCategory) return [];
      
      // Find the selected category in the current level
      const found = currentCategories.find(c => c.id === selectedCategory.id) || 
                   currentCategories.find(c => c.sub_categories?.some(sc => sc.id === selectedCategory.id))?.sub_categories.find(sc => sc.id === selectedCategory.id);
      
      if (!found?.sub_categories) return [];
      currentCategories = found.sub_categories;
    }
    
    return currentCategories;
  }, [organization.categories, selectedCategories]);

  const handleCategorySelect = (level, categoryId) => {
    const categories = getCategoriesAtLevel(level);
    const selectedCategory = categories.find(c => c.id === Number(categoryId));
    
    if (!selectedCategory) {
      // Clear selections from this level onwards
      setSelectedCategories(prev => prev.slice(0, level));
      handleFilterChange('category_id', selectedCategories[level - 1]?.id || null);
      return;
    }

    // Update selected categories up to this level
    setSelectedCategories(prev => {
      const newSelected = prev.slice(0, level);
      newSelected[level] = selectedCategory;
      return newSelected;
    });

    // Update the filter with the selected category
    handleFilterChange('category_id', selectedCategory.id);
  };

  const renderCategorySelects = () => {
    return (
      <div className="mb-4">
        <label className="text-sm text-gray-600 block mb-1">Categories</label>
        {[0, 1, 2, 3].map(level => {
          const categories = getCategoriesAtLevel(level);
          if (categories.length === 0) return null;
          
          return (
            <div key={level} className="mb-2">
              <select
                className="w-full p-1 text-sm border rounded"
                value={selectedCategories[level]?.id || ''}
                onChange={(e) => handleCategorySelect(level, Number(e.target.value))}
              >
                <option value="">
                  {level === 0 ? 'Select category' : `Select ${level === 1 ? 'sub' : `sub-${level}`} category`}
                </option>
                {categories.map(category => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </select>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-50">
      <Header onSignOut={onSignOut} />
      <main className="flex-grow container mx-auto py-6 px-4">
        {/* Header Section */}
        <div className="mb-8">
          <Link to="/dashboard" className="text-sm text-gray-600 hover:text-indigo-600 flex items-center gap-1 mb-4">
            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
            </svg>
            Back to Dashboard
          </Link>
          <div className="flex justify-between items-center">
            <h2 className="text-2xl font-semibold text-gray-800">
              {closet ? closet.name : 'Loading...'}
            </h2>
            <div className="text-sm text-gray-500">
              {totalCount} total products
            </div>
          </div>
        </div>

        {/* New Layout with Sidebar */}
        <div className="flex gap-6">
          {/* Left Sidebar Filters */}
          <div className="w-64 flex-shrink-0">
            <div className="bg-white rounded-lg shadow-sm p-4">
              <h3 className="text-lg font-medium text-gray-800 mb-4">Filters</h3>
              
              {/* Search */}
              <div className="mb-4">
                <label className="text-sm text-gray-600 block mb-1">Search</label>
                {renderFilterInput('q', 'Search products...')}
              </div>

              {/* Nested Categories */}
              {renderCategorySelects()}

              {/* Other Filters */}
              <div className="space-y-4">
                {/* Draft Status */}
                <div>
                  <label className="text-sm text-gray-600 block mb-1">Draft Status</label>
                  <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      checked={filters.is_draft === true}
                      onChange={() => handleFilterChange('is_draft', !filters.is_draft)}
                      className="rounded border-gray-300 text-indigo-600"
                    />
                    <span className="ml-2 text-sm text-gray-700">Draft</span>
                  </label>
                </div>

                {/* Move other filters here */}
                <div>
                  <label className="text-sm text-gray-600 block mb-1">Style</label>
                  {renderSelectFilter('style_id', organization.styles, 'All styles')}
                </div>

                <div>
                  <label className="text-sm text-gray-600 block mb-1">Platform</label>
                  {renderSelectFilter('platform_sold_on_id', organization.platforms, 'All platforms')}
                </div>

                {/* ... Add other filters ... */}
              </div>
            </div>
          </div>

          {/* Main Content */}
          <div className="flex-grow">
            {/* Products Table */}
            <div className="bg-white rounded-lg shadow-sm overflow-hidden">
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Image
                      </th>
                      <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Title
                      </th>
                      <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Brand
                      </th>
                      <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Categories
                      </th>
                      <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Days Listed
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {products.map((product) => (
                      <tr
                        key={product.id}
                        className="hover:bg-gray-50 transition-colors cursor-pointer"
                        onClick={() => handleRowClick(product.id)}
                      >
                        <td className="px-4 py-3 text-sm text-gray-900 whitespace-nowrap">
                        {getFirstImageUrl(product.images) ? (
                            <img 
                              src={`https://images.thriftforge.com/${getFirstImageUrl(product.images)}`} 
                              alt={product.title} 
                              className="w-12 h-12 object-cover rounded"
                            />
                          ) : (
                            <div className="w-12 h-12 bg-gray-100 rounded flex items-center justify-center">
                              <span className="text-gray-400 text-xs">No image</span>
                            </div>
                          )}
                        </td>
                        <td className="px-4 py-3 text-sm text-gray-900 whitespace-nowrap">
                          {product.title || 'N/A'}
                        </td>
                        <td className="px-4 py-3 text-sm text-gray-900 whitespace-nowrap">
                          {product.brand || 'N/A'}
                        </td>
                        <td className="px-4 py-3 text-sm text-gray-900 whitespace-nowrap">
                          {formatCategories(product.categories)}
                        </td>
                        <td className="px-4 py-3 text-sm text-gray-900 whitespace-nowrap">
                          {product.daysListed || 'N/A'}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              {/* Pagination */}
              <div className="px-4 py-3 border-t border-gray-200 bg-gray-50">
                <div className="flex items-center justify-between">
                  <p className="text-sm text-gray-700">
                    Showing {(page - 1) * pageSize + 1} - {Math.min(page * pageSize, totalCount)} of {totalCount} products
                  </p>
                  <div className="flex gap-2">
                    <button
                      onClick={() => setPage(prev => Math.max(prev - 1, 1))}
                      disabled={page === 1}
                      className="px-4 py-2 text-sm font-medium rounded-md text-gray-700 bg-white border border-gray-300 hover:bg-gray-50 disabled:bg-gray-100 disabled:text-gray-400"
                    >
                      Previous
                    </button>
                    <button
                      onClick={() => setPage(prev => prev + 1)}
                      disabled={page * pageSize >= totalCount}
                      className="px-4 py-2 text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 disabled:bg-indigo-300"
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  )
}

export default ProductPage
